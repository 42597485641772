<template>
  <div v-if="mytype == 'WINE'">
    <!--<div v-if="attr?.vintage">
      <img src="@/assets/vintage.svg" />&nbsp;{{ attr?.vintage }}
    </div>-->
    <div v-if="attr?.country"><img src="@/assets/country.svg" />&nbsp;{{ attr?.country }}</div>
    <div v-if="attr?.region"><img src="@/assets/region.svg" />&nbsp;{{ attr?.region }}</div>

    <div v-if="attr?.color">
      <img src="@/assets/wine-white.svg" v-if="attr?.color == 'White'" />
      <img src="@/assets/wine-red.svg" v-else-if="attr?.color == 'Red'" />
      <img src="@/assets/wine-rose.svg" v-else-if="attr?.color == 'Rose'" />
      <img src="@/assets/wine-orange.svg" v-else-if="attr?.color == 'Orange'" />
      <img src="@/assets/wine-white.svg" v-else />
      {{ attr?.color }} <span v-if="attr?.wine_type">&nbsp;&mdash;&nbsp;{{ removeNumbersDash() }}</span>
    </div>

    <div v-if="attr?.varietal"><img src="@/assets/wine-varietal.svg" />&nbsp;{{ attr?.varietal }}</div>
    <div v-if="attr?.rs_level"><img src="@/assets/rs-levels.svg" />&nbsp;{{ attr?.rs_level }}</div>
    <div v-if="attr?.farming_practice"><img src="@/assets/farming.svg" />&nbsp;{{ attr?.farming_practice }}</div>
    <div v-if="attr?.spirit_type"><img src="@/assets/spirit-type.svg" />&nbsp;{{ attr?.spirit_type }}</div>
    <div v-if="attr?.spirit_sub_type"><img src="@/assets/spirit-sub.svg" />&nbsp;{{ attr?.spirit_sub_type }}</div>
    <div v-if="attr?.appelation"><img src="@/assets/appellation.svg" />&nbsp;{{ attr?.appelation }}</div>
    <div v-if="attr?.kosher"><img src="@/assets/kosher.svg" />&nbsp;{{ attr?.kosher }}</div>
  </div>
  <!-- NEW TYPE -->
  <div v-else-if="mytype == 'SAKE'">
    <div v-if="attr?.region"><img src="@/assets/region.svg" />&nbsp;{{ attr?.region }}</div>
    <div v-if="attr?.spirit_type"><img src="@/assets/sake-type.svg" />&nbsp;{{ attr?.spirit_type }}</div>
    <div v-if="attr?.varietal"><img src="@/assets/sake-varietal.svg" />&nbsp;{{ attr?.varietal }}</div>
    <div v-if="attr?.rs_level"><img src="@/assets/rs-levels.svg" />&nbsp;{{ attr?.rs_level }}</div>
    <div v-if="attr?.sake_type"><img src="@/assets/sake-varietal.svg" />&nbsp;{{ attr?.sake_type }}</div>
    <!--<div v-if="attr?.rs_level"><img src="@/assets/rs-levels.svg" />&nbsp;{{ attr?.rice_category }}</div>-->
    <div v-if="attr?.kosher"><img src="@/assets/kosher.svg" />&nbsp;{{ attr?.kosher }}</div>
  </div>
  <!-- NEW TYPE -->
  <div v-else-if="mytype == 'SPIRITS'">
    <div v-if="attr?.vintage"><img src="@/assets/vintage.svg" />&nbsp;{{ attr?.vintage }}</div>
    <div v-if="attr?.country"><img src="@/assets/country.svg" />&nbsp;{{ attr?.country }}</div>
    <div v-if="attr?.region"><img src="@/assets/region.svg" />&nbsp;{{ attr?.region }}</div>
    <div v-if="attr?.spirit_type"><img src="@/assets/spirit-type.svg" />&nbsp;{{ attr?.spirit_type }}</div>
    <div v-if="attr?.spirit_sub_type"><img src="@/assets/spirit-sub.svg" />&nbsp;{{ attr?.spirit_sub_type }}</div>
    <div v-if="attr?.kosher"><img src="@/assets/kosher.svg" />&nbsp;{{ attr?.kosher }}</div>
  </div>
  <!-- NEW TYPE -->
  <div v-else-if="mytype == 'CIDER'">
    <div v-if="attr?.country"><img src="@/assets/country.svg" />&nbsp;{{ attr?.country }}</div>
    <div v-if="attr?.region"><img src="@/assets/region.svg" />&nbsp;{{ attr?.region }}</div>
    <div v-if="attr?.appelation"><img src="@/assets/appellation.svg" />&nbsp;{{ attr?.appelation }}</div>
    <div v-if="attr?.color"><img src="@/assets/cider-color.svg" />&nbsp;{{ attr?.color }}</div>
    <div v-if="attr?.rs_level"><img src="@/assets/rs-levels.svg" />&nbsp;{{ attr?.rs_level }}</div>

    <div v-if="attr?.farming_practice"><img src="@/assets/farming.svg" />&nbsp;{{ attr?.farming_practice }}</div>
    <div v-if="attr?.kosher"><img src="@/assets/kosher.svg" />&nbsp;{{ attr?.kosher }}</div>
  </div>
  <div v-else-if="mytype == 'HOSPITALITY SPIRITS'">
    <div v-if="attr?.country"><img src="@/assets/country.svg" />&nbsp;{{ attr?.country }}</div>
    <div v-if="attr?.region"><img src="@/assets/region.svg" />&nbsp;{{ attr?.region }}</div>
    <div v-if="attr?.spirit_type"><img src="@/assets/spirit-type.svg" />&nbsp;{{ attr?.spirit_type }}</div>

    <div v-if="attr?.farming_practice"><img src="@/assets/farming.svg" />&nbsp;{{ attr?.farming_practice }}</div>
    <div v-if="attr?.kosher"><img src="@/assets/kosher.svg" />&nbsp;{{ attr?.kosher }}</div>
  </div>
  <div v-else>
    <!--<div v-if="attr?.vintage">
      <img src="@/assets/vintage.svg" />&nbsp;{{ attr?.vintage }}
    </div>-->
    <div v-if="attr?.country"><img src="@/assets/country.svg" />&nbsp;{{ attr?.country }}</div>
    <div v-if="attr?.region"><img src="@/assets/region.svg" />&nbsp;{{ attr?.region }}</div>

    <div v-if="attr?.color">
      <img src="@/assets/wine-white.svg" v-if="attr?.color == 'White'" />
      <img src="@/assets/wine-red.svg" v-else-if="attr?.color == 'Red'" />
      <img src="@/assets/wine-rose.svg" v-else-if="attr?.color == 'Rose'" />
      <img src="@/assets/wine-orange.svg" v-else-if="attr?.color == 'Orange'" />
      <img src="@/assets/wine-white.svg" v-else />
      {{ attr?.color }} <span v-if="attr?.wine_type">&nbsp;&mdash;&nbsp;{{ removeNumbersDash() }}</span>
    </div>

    <div v-if="attr?.varietal"><img src="@/assets/wine-varietal.svg" />&nbsp;{{ attr?.varietal }}</div>
    <div v-if="attr?.rs_level"><img src="@/assets/rs-levels.svg" />&nbsp;{{ attr?.rs_level }}</div>
    <div v-if="attr?.farming_practice"><img src="@/assets/farming.svg" />&nbsp;{{ attr?.farming_practice }}</div>
    <div v-if="attr?.spirit_type"><img src="@/assets/spirit-type.svg" />&nbsp;{{ attr?.spirit_type }}</div>
    <div v-if="attr?.spirit_sub_type"><img src="@/assets/spirit-sub.svg" />&nbsp;{{ attr?.spirit_sub_type }}</div>
    <div v-if="attr?.appelation"><img src="@/assets/appellation.svg" />&nbsp;{{ attr?.appelation }}</div>
    <div v-if="attr?.kosher"><img src="@/assets/kosher.svg" />&nbsp;{{ attr?.kosher }}</div>
  </div>
</template>

<script>
export default {
  name: 'SkuAttributes',
  components: {},
  props: {
    attr: Object,
    mytype: {
      type: String,
      required: true,
      default: 'ITEM',
    },
  },
  data() {
    return {
      modNotes: '',
      edit: false,
    };
  },
  computed: {},
  methods: {
    // PUT OUR FUNCTIONS HERE FOR INTERACTION.
    removeNumbersDash() {
      return this.attr?.wine_type?.replace(/[0-9]/g, '').replace(/[-*]/g, '');
    },
  },
  created() {},
};
</script>
